function switchTheme(selector) {
  // console.log('switchTheme called...')
  const switches = document.querySelectorAll(selector);
  // let colorTheme = 'system';
  let colorTheme = localStorage.getItem('colorTheme') || 'light'; //commented to avoid security issue

  function changeState() {
    localStorage.setItem('colorTheme', colorTheme); //commented to avoid security issue
    document.documentElement.setAttribute('data-theme', colorTheme);

    if (document.documentElement.getAttribute('data-theme') === 'dark') {
      document.querySelector('.navbar-brand img').src = '/logo_transparent_dark.png'
      document.querySelector('.navbar-brand img').style.backgroundColor = 'transparent'
      if(document.querySelector('.logo img')){
        document.querySelector('.logo img').src = '/logo_transparent_dark.png'
        document.querySelector('.logo img').style.backgroundColor = 'transparent'
      }
    } else {
      document.querySelector('.navbar-brand img').src = '/logo_transparent.png'
      document.querySelector('.navbar-brand img').style.backgroundColor = 'transparent'
      if(document.querySelector('.logo img')) {
        document.querySelector('.logo img').src = '/logo_transparent.png'
        document.querySelector('.logo img').style.backgroundColor = 'transparent'
      }
    }
  }

  changeState();

  switches.forEach((el) => {
    el.addEventListener('click', () => {
      switch (colorTheme) {
        case 'dark':
          colorTheme = 'light';
          break;
        case 'light':
          colorTheme = 'dark'; // 'dark'
          break;
        default:
          colorTheme = 'dark';
      }
      changeState();
    });
  });
}

export { switchTheme };
