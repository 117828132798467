import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="publish"
export default class extends Controller {
  static targets = ["assessmentCD", "publishCD"];

  connect() {
    // console.log("Hello, Stimulus!", this.element);
    let assessment_cd = 0;
    let publish_cd = 0;
    if (
      document.querySelector("input[name='lecture[assessment_cd]']:checked")
    ) {
      assessment_cd = document.querySelector(
        "input[name='lecture[assessment_cd]']:checked"
      ).value;
    }
    if (document.querySelector("input[name='lecture[publish_cd]']:checked")) {
      publish_cd = document.querySelector(
        "input[name='lecture[publish_cd]']:checked"
      ).value;
      // this.propagatePublish(publish_cd);
    }
    this.propagateAssess(assessment_cd);
  }

  handleAssessClicked(event) {
    const assessment_cd = event.target.value;
    if (
      document.querySelector("input[name='lecture[publish_cd]'][value='3']")
    ) {
      document.querySelector(
        "input[name='lecture[publish_cd]'][value='3']"
      ).checked = true;
    }
    this.propagateAssess(assessment_cd);
  }

  handlePublishClicked(event) {
    const publish_cd = event.target.value;
    this.propagatePublish(publish_cd);
  }

  propagateAssess(assessment_cd) {
    // console.log(assessment_cd);
    if (assessment_cd == 1) {
      this.publishCDTarget.classList.remove("d-none");
      this.assessmentCDTarget.classList.add("d-none");
    } else {
      this.publishCDTarget.classList.add("d-none");
      this.assessmentCDTarget.classList.remove("d-none");
    }
  }

  propagatePublish(publish_cd) {
    if (publish_cd == 4) {
      this.assessmentCDTarget.classList.remove("d-none");
      document.querySelector(
        "input[name='lecture[assessment_cd]'][value='0']"
      ).checked = true;
      this.publishCDTarget.classList.add("d-none");
    } else {
      this.assessmentCDTarget.classList.add("d-none");
      this.publishCDTarget.classList.remove("d-none");
    }
  }
}
