import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = {
    formView: Boolean,
    newCategory: Array,
    firstChildrenId: String,
    secondChildrenId: String,
    firstLabel: String,
    secondLabel: String,
  };

  connect(){
    // console.log(this.newCategory)
  }

  selectLeaf(id) {
    // console.log(id);
    fetch(`/admin/categories/${id}/lookup.json`)
      .then((response) => response.json())
      .then(
        (data) =>
          ($('#lecture_new_category, #new_category')[0].value = data.path_ids)
      );
  }

  handleSelectLeaf(e) {
    this.selectLeaf(e.target.value);
  }

  selectChange(id) {
    // console.log('selectChange method called...', id)
    if (this.hasFirstChildrenIdValue) {
      $(this.firstChildrenIdValue)
        .empty()
        .append(`<option value="">${this.firstLabelValue}</option>`);
    }
    if (this.hasSecondChildrenIdValue) {
      $(this.secondChildrenIdValue)
        .empty()
        .append(`<option value="">${this.secondLabelValue}</option>`);
    }

    let fetch_url;
    if (id == '') {
      $.get(`/admin/categories.js`);
    } else {
      fetch_url = `/admin/categories/${id}/children.json`;
      // console.log(fetch_url);
      // $.get(`/categories/${id}/children.js`);
      fetch(fetch_url)
        .then((response) => response.json())
        .then((data) => {
          // console.log(data);
          data.forEach((category) => {
            // console.log(category.id, parseInt(id));
            if (this.hasFirstChildrenIdValue) {
              // console.log("in the category controller")
              $(this.firstChildrenIdValue).append(
                `<option value="${category.id}">${category.name}</option>`
              );
            } else {
              console.log('firstChildrenIdValue not shown.');
            }
          });
        });
    }
  }

  handleSelectChange(e) {
    // console.log('select event called...');
    this.selectChange(e.target.value);
    // if (this.hasFormViewValue && this.formViewValue) {
    this.selectLeaf(e.target.value);
    // }
  }
}
