import { Controller } from '@hotwired/stimulus';
import axios from 'axios';

// Connects to data-controller="video"
export default class extends Controller {
  static values = {
    lectureId: Number,
    alias: String,
    loaded: Boolean,
    key: String,
  };

  connect() {
    if(this.hasKeyValue) {
      const _this = this;
      $(this.element).html(
        `<div class="alert alert-warning mb-0"><i class="fas fa-sync fa-spin me-2"></i> 잠시만 기다려 주세요. 동영상을 렌더링 중입니다.</div>
<small>렌더링 상태 : <span class='badge rounded-pill bg-danger'>진행 중</span> <small class='text-muted'>(${new Date().toLocaleTimeString()} 시작)</small></small>
`
      );
      const uploadCheckInterval = setInterval(function () {
        axios
          .get(`https://api.vimeo.com/videos?uris=/videos/${_this.keyValue}`, {
            headers: {
              Authorization: 'bearer 7b8989cb73f33636c1d6dbacb3541b9c',
              'Content-Type': 'application/json',
            },
          })
          .then((res) => {
            const data_source = res.data.data[0];
            // console.log(data_source);
            if (data_source === undefined) {
              $(_this.element).html(
                `<div class="alert alert-danger mb-0"><i class="fas fa-info-circle"></i> 동영상이 삭제되었습니다.</div>`
              );
            } else if (data_source.transcode['status'] !== 'complete') {
              console.log(
                `렌더링 상태 ⇢ ${
                  data_source.transcode['status']
                } (${new Date().toLocaleString()})`
              );
            } else {
              renderIframe(
                _this.keyValue,
                _this.aliasValue,
                data_source,
                _this.element
              );
              console.log(
                `렌더링 상태 ⇢ ${
                  data_source.transcode['status']
                } (${new Date().toLocaleString()})`
              );
              clearInterval(uploadCheckInterval);
              // fetch(
              //   `/admin/lectures/${_this.lectureIdValue}/render_thumbnails`
              // );
            }
          });
      }, 1000);
    }
  }
}

function renderIframe(keyValue, aliasValue, data, thisObject) {
  const html_content = `
        <a target="_blank" href="https://vimeo.com/${keyValue}" class='text-decoration-none'><svg class="svg-inline--fa fa-vimeo-v fa-w-14" aria-hidden="true" focusable="false" data-prefix="fab" data-icon="vimeo-v" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" data-fa-i2svg=""><path fill="currentColor" d="M447.8 153.6c-2 43.6-32.4 103.3-91.4 179.1-60.9 79.2-112.4 118.8-154.6 118.8-26.1 0-48.2-24.1-66.3-72.3C100.3 250 85.3 174.3 56.2 174.3c-3.4 0-15.1 7.1-35.2 21.1L0 168.2c51.6-45.3 100.9-95.7 131.8-98.5 34.9-3.4 56.3 20.5 64.4 71.5 28.7 181.5 41.4 208.9 93.6 126.7 18.7-29.6 28.8-52.1 30.2-67.6 4.8-45.9-35.8-42.8-63.3-31 22-72.1 64.1-107.1 126.2-105.1 45.8 1.2 67.5 31.1 64.9 89.4z"></path></svg><!-- <i class="fab fa-vimeo-v"></i> Font Awesome fontawesome.com --><span class="fa5-text">Original Video Download</span></a>
        <div class="plyr__video-embed mt-2" id="player">
          <iframe style="border:1px solid black;" src="https://player.vimeo.com/video/${keyValue}?loop=false&amp;byline=false&amp;portrait=false&amp;title=false&amp;speed=true&amp;transparent=0&amp;gesture=media" width="250" height="142" allowfullscreen="" allowtransparency="" allow="autoplay"></iframe>
        </div>
      `;
  $(thisObject).html(html_content);
  // html_content = data_source.embed['html'];
  // console.log(html_content);

  const downloads = data.download;
  // console.log(downloads);
  const table_rows = downloads
    .sort(function (a, b) {
      return a.width - b.width;
    })
    .map((download) => {
      return `
      <tr>
        <td>${download['public_name']}</td>
        <td>${download['type']}</td>
        <td>${download['width']} x ${download['height']}</td>
        <td><span class='${
          download['size'] > 209_715_200 ? 'text-danger' : ''
        }'>${download['size_short']}</span></td>
        <td><a href='${
          download['link']
        }' class='text-decoration-none'><i class='fa fa-download'></i> Download</a></td>
      </tr>
    `;
    });

  $(thisObject).append(`
    <div>> Upload: ${data.upload['status']}</div>
    <div>> Transcode: ${data.transcode['status']}</div>
    <table class='table table-bordered table-striped table-hover mt-2 mb-0'>
    <thead class='table-header'>
      <th>Quality</th>
      <th>Type</th>
      <th>Resolution</th>
      <th>Size</th>
      <th>Download Link</th>
    </thead>
      ${table_rows.join('')}
    </table>
  `);

  // $(thisObject).hide().stop().slideDown();
}
