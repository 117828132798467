import { Controller } from '@hotwired/stimulus';
import 'jstree';

// Connects to data-controller="jstree"
export default class extends Controller {
  static values = {
    categoryId: Number,
    locale: String,
    chargeCd: Number,
  };

  connect() {
    // console.log("Jstree Controller connected...")
    // console.log(this.localeValue)
    // console.log(this.chargeCdValue)

    $(this.element)
      .jstree({
        core: {
          themes: {
            icons: false,
          },
          data: {
            url: `/categories/${this.categoryIdValue}/tree.json?locale=${
              this.localeValue
            }${
              this.hasChargeCdValue ? '&charge_cd=' + this.chargeCdValue : ''
            }`,
            data: (node) => {
              return {
                id: node.id,
                text: node.text,
              };
            },
          },
        },
      })
      .on('loaded.jstree', () => {
        $(this.element).jstree('open_all');
      })
      .on('select_node.jstree', (e) => {
        // console.log(e.target);
        const selected = $(this.element).jstree('get_selected', true);
        // console.log(selected[0].original);
        // window.location.hash = selected[0].original['anchor'];
        // window.location.href = selected[0].original['url'];
        Turbo.visit(selected[0].original['url']);
        // console.log(window.location);
      });
  }
}
